<template>
  <div>
    <PageHeader>
      <template slot="right">
        <label class="d-inline-flex align-items-center">
          <b-form-checkbox
            v-model="toggleStatus"
            switch
          >
            ดึงข้อมูลอัตโนมัติ (ทุกๆ
            {{ intervalTime / 1000 }} วินาที)
          </b-form-checkbox>
        </label>
      </template>
    </PageHeader>
    <b-button-group class="mb-2" >
      <b-button
        v-for="item in menu"
        :key="item.name"
        :variant="currentMenu === item.name ? '' : 'light'"
        @click="currentMenu = item.name"
      >
        {{ item.label }}
        <b-badge
          v-if="item.name === 'pending'"
          variant="danger"
          class="float-right ml-2"
        >
          {{ +withdrawsTotal === +selectedLimit ? withdrawsTotal + "+" : withdrawsTotal }}
        </b-badge>
        <b-badge
            v-if="item.name === 'hide'"
            variant="danger"
            class="float-right ml-2"
        >
          {{ +withdrawHideTotal === +selectedLimit ? withdrawHideTotal + "+" : withdrawHideTotal }}
        </b-badge>
        <b-badge
            v-if="item.name === 'processing'"
            variant="danger"
            class="float-right ml-2"
        >
          {{ +withdrawProcessingTotal === +selectedLimit ? withdrawProcessingTotal + "+" : withdrawProcessingTotal }}
        </b-badge>
        <b-badge
          v-if="item.name === 'done'"
          variant="danger"
          class="float-right ml-2"
        >
          {{ +withdrawDoneTotal === +selectedLimit ? withdrawDoneTotal + "+" : withdrawDoneTotal }}
        </b-badge>
      </b-button>
    </b-button-group>
    <div>
      <component :is="selectedComponent" :type="'withdraw'" @on-agent-change="onAgentIdChange" @on-master-change="onMasterIdChange" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'รายการถอน'
  },
  components: {
    PendingTransactions: () => import('./components/pending.vue'),
    DoneTransactions: () => import('./components/done.vue'),
    ProcessingTransactions: () => import('./components/processing.vue'),
    HideTransactions: () => import('./components/hide.vue'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      toggleStatus: true,
      currentMenu: 'pending',
      menu: [ {name: 'pending', label: 'รอดำเนินการ'}, {name:'hide', label: 'รายการที่ซ่อนไว้'}, {name:'processing', label: 'กำลังดำเนินการ'}, {name:'done', label: 'ดำเนินการแล้ว'} ],
      intervalIdentifier: null,
      intervalTime: 10000,
    }
  },
  computed: {
    ...mapGetters(['isAgent', 'withdraws', 'withdrawHide', 'withdrawProcessing']),
    ...mapState({
      intervalFetch: (state) => state.credit.intervalFetch,
    }),
    withdrawsTotal() {
      return this.withdraws?.items?.length || 0
    },
    withdrawHideTotal() {
      return this.withdrawHide?.items?.length || 0
    },
    withdrawProcessingTotal() {
      return this.withdrawProcessing?.items?.length || 0
    },
    withdrawDoneTotal() {
      return this.withdrawDone?.items?.length || 0
    },
    selectedComponent() {
      switch (this.currentMenu) {
        case 'pending':
          return 'PendingTransactions'
        case 'done':
          return 'DoneTransactions'
        case 'hide':
          return 'HideTransactions'
        case 'processing':
          return 'ProcessingTransactions'
        default:
          return ''
      }
    },
  },
  watch: {
    toggleStatus(val) {
      if (val) {
        this.startInterval()
      } else {
        this.stopInterval()
      }
    },
    isAgent(val) {
      if(val) {
        this.fetchData()
      }
    }
  },
  mounted() {
    this.fetchData()
    this.startInterval();
  },
  beforeDestroy() {
    this.stopInterval()
  },
  methods: {
    ...mapActions([
      'fetchWithdraws',
      'fetchWithdrawProcessing',
      'fetchWithdrawHide',
    ]),
    startInterval() {
      this.intervalIdentifier = setInterval(() => {
        if(this.isAgent || this.agentId.length) {
          this.fetchWithdraws({
            limit: this.selectedLimit,
            page: this.currentPage,
            search: this.search,
            agentId: this.agentId,
            masterId: this.masterId,
          })
        }
      }, this.intervalTime)
    },
    stopInterval() {
      clearInterval(this.intervalIdentifier)
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
    },
    fetchData() {
      if(this.isAgent) {
        this.fetchWithdraws({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: this.search,
          agentId: this.agentId,
          masterId: this.masterId,
        })
        this.fetchWithdrawProcessing({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: this.search,
          agentId: this.agentId,
          masterId: this.masterId,
        })
        this.fetchWithdrawHide({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: this.search,
          agentId: this.agentId,
          masterId: this.masterId,
        })
      }
    },
  },
}
</script>
